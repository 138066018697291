.btn:focus {
  box-shadow: none;
}
.add_newitem_btn {
  border: 1px solid lightgoldenrodyellow;
  padding: 0.5rem 2rem;
  border-radius: 7px;
  background: #c6aa58d9;
  color: white;
  font-weight: 500;
  font-size: 1.1rem;
}
.add_newitem_btn:hover {
  background: #b19542d9;
}
#search_box {
  width: 68%;
}
.add-item-btn,
.itm-action-btn {
  background: #0d533ae6;
  font-weight: 600;
  color: white;
}
.add-item-btn:hover,
.itm-action-btn:hover {
  background: #1d8d68;
  color: white;
}
.item_list,
.item_details,
.item_transactions {
  background: #eae9c4;
}

.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 28px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 32px;
}

.slider.round:before {
  border-radius: 50%;
}

.adjsutstock_btn {
  background: #c6aa589e;
  border: 1px solid white;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  padding: 0.5rem 1rem;
}
.adjsutstock_btn:hover {
  background: #c6aa58;
}
.trns_table_menu {
  position: relative;
  display: inline-block;
}
.dropdown-button {
  cursor: pointer;
}
.trans-dropdown-menu {
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: none;
  z-index: 10;
}

/* .trns_table_menu:hover .trans-dropdown-menu {
    display: block;
} */

.trans-dropdown-menu li {
  padding: 5px 25px;
  display: flex;
  border-bottom: 1px solid khaki;
  justify-content: left;
}

.trans-dropdown-menu li a {
  color: black;
  text-decoration: none;
}

.trans-dropdown-menu li:hover {
  background-color: #f0f0f0;
}
.trns_table_menu i {
  z-index: 5;
}
.items_table_section {
  height: 55vh;
  overflow-y: auto;
}
.items_table_section::-webkit-scrollbar {
  display: none;
}
.items_table_section th {
  position: sticky;
  top: 0;
  background-color: #eae9c4;
}
.items_table_ {
  height: 80vh;
  overflow-y: auto;
}
.items_table_::-webkit-scrollbar {
  display: none;
}
.items_table_ th {
  position: sticky;
  top: 0;
  background-color: #eae9c4;
}

@media print {
  .userItems .page[size="A4"] {
    width: 21cm;
    height: 29.7cm;
  }
}

.trns_table_menu .dropdown-toggle::after{
  content: none;
}