.p-datatable-tbody tr td {
  padding: 0.2rem 0rem;
}

.p-datatable-thead tr th {
  padding: 0.2rem 0rem;
  border-bottom: 1px solid gray;
}

.userDashboard .bg-light {
  background: #eae9c4 !important;
}
.userDashboard .bg-white {
  background-color: #407d58 !important;
}
.userDashboard .content {
  background: rgb(255, 255, 255);
}
.userDashboard .text-primary {
  color: #003e27 !important;
}

.userStockReports .action_btns {
  background: #0d533ae6;
  font-weight: 500;
  color: white;
  height: 2.5rem;
  width: 6rem;
}
.userStockReports .action_btns:hover {
  background: #1d8d68;
  color: white;
}
.userStockReports .all_stock_reports {
  background: #eae9c4;
}

.userStockReports table tr {
  cursor: pointer;
}
.userStockReports .table-responsive {
  max-height: 100vh;
}
.userStockReports .table-responsive::-webkit-scrollbar {
  display: none;
}

.userStockReports .submitShareEmailBtn {
  background: #c6aa589e;
  border: 1px solid white;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  padding: 0.5rem 1rem;
}
.userStockReports .submitShareEmailBtn:hover {
  background: #c6aa58;
}

.userSalesReports .dropdown-content {
  z-index: 1;
  display: none;
  position: absolute;
  background-color: #b4b4b450;
  backdrop-filter: blur(5px);
  padding: 10px;
  border: 1px solid #1d8d68;
  border-radius: 5px;
  box-shadow: 3px 5px 5px 5px rgba(0, 0, 0, 0.201);
}

.userSalesReports .dropdown-content input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid rgb(168, 168, 168);
}

.userSalesReports .dropdown-content button {
  padding: 8px 15px;
  background-color: rgb(0, 154, 108);
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 3px;
}

.userSalesReports .action_btns {
  background: #0d533ae6;
  font-weight: 500;
  color: white;
  font-size: 15px;
}

@media (max-width : 430px){
  .userSalesReports .action_btns {
    font-size: 12px;
  }

  #dropdownContent4, #dropdownContent3{
    right: 0;
  }
}

@media (min-width : 431px) and (max-width: 525px){
  .userSalesReports .action_btns {
    font-size: 13px;
  }
  #dropdownContent4, #dropdownContent3{
    right: 0;
  }
}

@media (min-width : 526px) and (max-width: 768px){
  .userSalesReports .action_btns {
    font-size: 14px;
  }
}