/********** Template CSS **********/
:root {
  --primary: #003e27;
  --primary2: #09593c;
  --secondary: #191c24;
  --light: #6c7293;
  --dark: #000000;
}
.b-primary {
  background: #003e29;
}
.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

main {
  min-height: 76vh;
}
/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

.nav_search_bar {
  width: 40%;
}

/*** Button ***/
.btn {
  transition: 0.5s;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50px;
}

/*** Layout ***/
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  height: 100vh;
  overflow-y: auto;
  /* background: var(--primary); */
  transition: 0.5s;
  z-index: 999;
}

.sidebar::-webkit-scrollbar{
  display: none;
}
/* .sidebar .navbar{
  background: var(--primary);
} */

#userSection .content {
  margin-left: 250px;
  min-height: 100vh;
  background: white;
  transition: 0.5s;
}

@media (min-width: 992px) {
  .sidebar {
    margin-left: 0;
  }

  .sidebar.open {
    margin-left: -250px;
  }

  #userSection .content {
    width: calc(100% - 250px);
  }

  #userSection .content.open {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 991.98px) {
  .sidebar {
    margin-left: -250px;
  }

  .sidebar.open {
    margin-left: 0;
  }

  #userSection .content {
    width: 100%;
    margin-left: 0;
  }
}

/*** Navbar ***/

.sidebar .navbar .navbar-nav .nav-link {
  padding: 7px 10px 7px 13px;
  /* color: var(--light); */
  color: white;
  font-weight: 500;
  border-left: 3px solid var(--secondary);
  border-radius: 0 30px 30px 0;
  outline: none;
  cursor: pointer;
}

.sidebar .navbar .navbar-nav .nav-link:hover,
.sidebar .navbar .navbar-nav .nav-link.active {
  color: rgb(11, 11, 11);
  background: white;
  border-color: rgb(254, 232, 30);
}
#userSection .content .navbar .notification-dropdown .dropdown-item:hover {
  background: #212529;
  color: white;
}
.add-icon {
  color: white;
}
.sidebar .navbar .navbar-nav .nav-link:hover .add-icon,
.sidebar .navbar .navbar-nav .nav-link.active .add-icon {
  color: #c6aa58;
}
.add-icon:hover {
  transform: scale(1.5);
}
.sidebar .navbar .navbar-nav .nav-link i {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #c6aa58;
  border-radius: 40px;
}

.sidebar .navbar .navbar-nav .nav-link:hover i,
.sidebar .navbar .navbar-nav .nav-link.active i {
  background: #c6aa58;
  color: white;
}

.sidebar .navbar .dropdown-toggle::after {
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  transition: 0.5s;
}

.sidebar .navbar .dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(-180deg);
}

.sidebar .navbar .dropdown-item {
  padding-left: 50px;
  color: rgb(255, 255, 255);
}

.sidebar .navbar .dropdown-item:hover,
.sidebar .navbar .dropdown-item.active {
  background: white;
  color: rgb(2, 2, 2);
  border-color: rgb(254, 232, 30);
}

#userSection .content .navbar .navbar-nav .nav-link {
  margin-left: 25px;
  padding: 12px 0;
  color: white;
  outline: none;
}

#userSection .content .navbar .navbar-nav .nav-link:hover,
#userSection .content .navbar .navbar-nav .nav-link.active {
  color: rgb(220, 219, 219);
}

#userSection .content .navbar .sidebar-toggler,
#userSection .content .navbar .navbar-nav .nav-link i {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #c6aa58;
  border-radius: 40px;
}

#userSection .content .navbar .dropdown-item {
  color: white;
}

#userSection .content .navbar .dropdown-item:hover,
#userSection .content .navbar .dropdown-item.active {
  background: white;
  color: black;
}

#userSection .content .navbar .dropdown-toggle::after {
  margin-left: 6px;
  vertical-align: middle;
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  transition: 0.5s;
}

#userSection .content .navbar .dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(-180deg);
}

@media (max-width: 575.98px) {
  #userSection .content .navbar .navbar-nav .nav-link {
    margin-left: 15px;
  }
}

/*** Date Picker ***/
.bootstrap-datetimepicker-widget.bottom {
  top: auto !important;
}

.bootstrap-datetimepicker-widget .table * {
  border-bottom-width: 0px;
}

.bootstrap-datetimepicker-widget .table th {
  font-weight: 500;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  padding: 10px;
  border-radius: 2px;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background: var(--primary);
}

.bootstrap-datetimepicker-widget table td.today::before {
  border-bottom-color: var(--primary);
}

/*** Testimonial ***/
.progress .progress-bar {
  width: 0px;
  transition: 2s;
}

/*** Testimonial ***/
.testimonial-carousel .owl-dots {
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  border: 5px solid var(--primary);
  border-radius: 15px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  background: var(--dark);
  border-color: var(--primary);
}
